<template>
  <div style="padding: 1rem;">

    <b-card >
      <b-row class="mb-1 pl-1 pr-0">
        <b-col>
          <h4>{{$t('Register creation')}}</h4>
          <!-- <h6>{{$t('Access to Property', {house: myContracts.contractSelected.housingname})}}</h6> -->
        </b-col>
        <b-col>
          <b-button class="float-right" size="sm" variant="warning" :to="{ name: 'my-property' }">
            <feather-icon icon="ArrowLeftIcon" size="16" class="align-middle" style="color: white" />
          </b-button>
        </b-col>
      </b-row>

      <AccessForm
        :isLoadingControlAccessData="isLoadingControlAccessData"
        :isSavingControlAccessData="isSavingControlAccessData"
        :accessData="initialData"
        :originalAccessData="initialData"
        @save-register="saveRecord"
      />
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import AccessForm from '@/modules/myProperty/components/AccessControl/AccessForm'

import { showAlertMessage } from '@/helpers/helpers'
import { utils } from "@/modules/owners/mixins/utils"

export default {
    mixins:[ utils ],
	components: {
		AccessForm,
	},
	data() {
		return {
			isLoadingControlAccessData: false,
			isSavingControlAccessData: false,
			initialData: {
				name: null,
				lastname: null,
				email: null,
				reason: null,
				type: null,
				guestData: [],
				fullDay: false,
				dateIn: null,
				dateOut: null,
				timeIn: null,
				timeOut: null,
				notes: null
			}
		}
	},
	computed:{
		...mapState('auth', ['user', 'myContracts']),
	},
	methods: {
		...mapActions('myProperty', ['saveControlAccess']),
		async saveRecord(data){
			// validar si es admin desde mixin en utils
			if( this.isAdmin ){
				return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
			}

			this.isSavingControlAccessData = true
			data.idOwner = this.user.idOwner
			data.idContract = this.myContracts.contractSelected.id
			const response = await this.saveControlAccess(data)
			this.isSavingControlAccessData = false
			
			if (response) {
				showAlertMessage( this.$t('Access saved'), 'InfoIcon', `🎉🎉 ${this.$t('Register saved')}`, 'success', 4000, 'bottom-right')
				this.$router.push({name: 'my-property'})
				await this.pushData('onSubmit', 'accessControlLogWasCreated', 'accessControlLogWasCreated', this.$route.name) // push a back del registro
			}

			if( !response ){
				showAlertMessage( this.$t('something failed'), 'InfoIcon', this.$t(`try again`), 'danger', 4000, 'bottom-right')
			}
		}
	}
}
</script>

<style>

</style>